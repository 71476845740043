var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        visible: _vm.visible,
        closable: true,
        "mask-closable": false,
        size: "normal",
        title: (_vm.mode == "add" ? "添加" : "编辑") + "字段组",
        "ok-text": "确认",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "字段组名称", prop: "fieldGroupName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入1-20个字符" },
                model: {
                  value: _vm.form.fieldGroupName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fieldGroupName", $$v)
                  },
                  expression: "form.fieldGroupName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }